import React, { useEffect, useState, useRef } from 'react';
import logo from '../../images/logo.png'
import './Profile.css'
import { Link as Anchor } from 'react-router-dom';
import baseURL from '../url';
import contact from '../contact'
export default function Profile() {

    const [images, setImages] = useState([]);


    useEffect(() => {

        cargarBanners();
    }, []);

    const cargarBanners = () => {
        fetch(`${baseURL}/bannersGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const bannerImages = data.banner.map(banner => banner.imagen);
                setImages(bannerImages);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error)

            });
    };


    return (
        <div className='profileContain'>
            <div className='fondo'>
                <img src={images[0]} alt={`imagen`} />
            </div>
            {
                contact.map(item => (
                    <div className='profileText'>

                        <img src={logo} alt="" />
                        <h2>{item.nombre}</h2>
                        <div className='socials'>
                            <Anchor to={item.instagram} target="_blank"><i className='fa fa-instagram'></i></Anchor>
                            <Anchor to={`tel:${item.telefono}`} target="_blank"><i className='fa fa-whatsapp'></i></Anchor>
                            <Anchor to={item.facebook} target="_blank"><i className='fa fa-facebook'></i></Anchor>
                        </div>
                        <Anchor to={`mailto:${item.email}`} target="_blank">{item.email}</Anchor>
                        <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(item.direccion)}`} target="_blank">{item.direccion}</Anchor>
                    </div>
                ))
            }
        </div>
    )
}

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import './Detail.css'
import Modal from 'react-responsive-modal';
import Modal2 from 'react-modal';
import 'react-responsive-modal/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEnvelope, faExternalLinkAlt, faStar, faTrash, faHeart } from '@fortawesome/free-solid-svg-icons';
import whatsappIcon from '../../images/wpp.png';
import { Link as Anchor, useNavigate, useLocation } from "react-router-dom";
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import baseURL from '../url';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DetailLoading from "../DetailLoading/DetailLoading";
import moneda from '../moneda';
import logo from '../../images/logo.png';
export default function Detail() {
    const navigate = useNavigate();
    const swiperRef = useRef(null);
    SwiperCore.use([Navigation, Pagination, Autoplay]);
    const { idProducto } = useParams();
    const [producto, setProducto] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [productos, setProductos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contactos, setContactos] = useState([]);
    const [favoritos, setFavoritos] = useState([]);
    const [contactoSeleccionado, setContactoSeleccionado] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        cargarProductos();
        cargarContacto();
        cargarFavoritos();


    }, []);


    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const mezclarArray = (array) => {
        let arreglo = [...array];
        for (let i = arreglo.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [arreglo[i], arreglo[j]] = [arreglo[j], arreglo[i]];
        }
        return arreglo;
    };


    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const productosMezclados = mezclarArray(data.productos || []);
                setProductos(productosMezclados);
                console.log(productosMezclados);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error);
                setLoading(true);
            });
    };



    const cargarFavoritos = () => {
        const storedFavoritos = JSON.parse(localStorage.getItem('favoritos')) || [];
        setFavoritos(storedFavoritos);
    };

    useEffect(() => {
        const product = productos.find((e) => e.idProducto === parseInt(idProducto));
        setProducto(product);
    }, [idProducto, productos]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    function handleCompartirClick() {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: 'Echa un vistazo a este producto',
                url: window.location.href,
            })
                .then(() => console.log('Contenido compartido correctamente'))
                .catch((error) => console.error('Error al compartir:', error));
        } else {
            console.error('La API de compartir no está disponible en este navegador.');
        }
    }

    const handleWhatsappMessage = () => {
        // Encuentra el contacto que tenga el mismo idUsuario que el producto
        const contacto = contactos.find(c => c.idUsuario === producto.idUsuario);

        // Si no se encuentra un contacto correspondiente, se maneja el error
        if (!contacto) {
            console.error('No se encontró el contacto para el usuario');
            return;
        }

        const phoneNumber = contacto.telefono;
        const name = contacto.nombre;
        const title = encodeURIComponent(producto?.titulo?.replace(/\s+/g, '-'));
        const formattedPrice = Number(producto?.precio).toLocaleString('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        const message = `Hola ${name}🌟, quisiera más información sobre la moto\n\n✅ *${title}*\n      ${moneda} ${formattedPrice}\n\nPublicada en https://motosdesegunda.com `;

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');
    };



    const goBack = () => {
        if (location.key !== 'default') {
            navigate(-1);
        } else {
            navigate('/');
        }
    };







    const agregarAFavoritos = (idProducto) => {
        const favList = [...favoritos];
        const index = favList.indexOf(idProducto);
        if (index === -1) {
            // Si el producto no está en favoritos, lo agregamos
            favList.push(idProducto);
            setFavoritos(favList);
            localStorage.setItem('favoritos', JSON.stringify(favList));
            console.log('Producto agregado a favoritos');

        } else {
            // Si el producto está en favoritos, lo eliminamos
            favList.splice(index, 1);
            setFavoritos(favList);
            localStorage.setItem('favoritos', JSON.stringify(favList));
            console.log('Producto eliminado de favoritos');
        }
    };

    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };


    const openModal = (contacto) => {
        setContactoSeleccionado(contacto);  // Asigna el contacto seleccionado
        setModalIsOpen(true);  // Abre el modal
    };

    const closeModal = () => {
        setModalIsOpen(false);  // Cierra el modal
    };


    const handleWhatsappClick = () => {
        if (contactoSeleccionado) {
            const phoneNumber = contactoSeleccionado.telefono;
            const message = `Hola ${contactoSeleccionado.nombre}, me gustaría obtener más información sobre la moto.\n\nPublicada en https://motosdesegunda.com`;
            const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        }
    };

    const handleCallClick = () => {
        if (contactoSeleccionado) {
            const phoneNumber = contactoSeleccionado.telefono;
            window.location.href = `tel:${phoneNumber}`;
        }
    };

    const handleEmailClick = () => {
        if (contactoSeleccionado) {
            const email = contactoSeleccionado.email;
            const subject = encodeURIComponent("Solicitud de información");
            const body = encodeURIComponent(`Hola ${contactoSeleccionado.nombre},\n\nMe gustaría obtener más información sobre el producto.`);
            window.location.href = `mailto:${email}?subject=${subject}&body=${body}`;
        }
    };
    if (!producto) {
        return <DetailLoading />;
    }


    return (


        <div className="detail">

            <ToastContainer />
            <div className="deFlexDetail">
                <button className="back" onClick={goBack}> <FontAwesomeIcon icon={faArrowLeft} /> </button>

                <div className="deFLexIcon">
                    <button onClick={() => agregarAFavoritos(producto.idProducto)} className='favoritos-btn'>
                        <FontAwesomeIcon icon={faHeart} style={{ color: favoritos.includes(producto.idProducto) ? 'red' : 'gray' }} />
                    </button>
                    <button className="share" onClick={handleCompartirClick}> <FontAwesomeIcon icon={faExternalLinkAlt} /> </button>
                </div>


            </div>
            <div className="detail-contain">
                <SwiperSlide id={"swiperDetail"} >
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        loop={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 2.5 }}
                        navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                        autoplay={{ delay: 3000 }} // Cambia el valor de 'delay' según tus preferencias
                        pagination={{ clickable: true, }}
                        onSwiper={(swiper) => {
                            console.log(swiper);
                            swiperRef.current = swiper;
                        }}

                    >

                        {
                            producto.imagen1 ?
                                (
                                    <SwiperSlide  >
                                        <img
                                            src={producto.imagen1}
                                            alt={producto.titulo}
                                            className="imagen1"
                                            onClick={() => {
                                                setModalImage(producto.imagen1);
                                                setIsModalOpen(true);
                                            }}
                                        />
                                    </SwiperSlide>
                                ) : (
                                    <>
                                    </>
                                )
                        }

                        {
                            producto.imagen2 ?
                                (
                                    <SwiperSlide  >
                                        <img
                                            src={producto.imagen2}
                                            alt={producto.titulo}
                                            className="imagen2"
                                            onClick={() => {
                                                setModalImage(producto.imagen2);
                                                setIsModalOpen(true);
                                            }}
                                        />
                                    </SwiperSlide>
                                ) : (
                                    <>
                                    </>
                                )
                        }
                        {
                            producto.imagen3 ?
                                (
                                    <SwiperSlide  >
                                        <img
                                            src={producto.imagen3}
                                            alt={producto.titulo}
                                            className="img"
                                            onClick={() => {
                                                setModalImage(producto.imagen3);
                                                setIsModalOpen(true);
                                            }}
                                        />
                                    </SwiperSlide>
                                ) : (
                                    <>
                                    </>
                                )
                        }
                        {
                            producto.imagen4 ?
                                (
                                    <SwiperSlide  >
                                        <img
                                            src={producto.imagen4}
                                            alt={producto.titulo}
                                            className="imagen4"
                                            onClick={() => {
                                                setModalImage(producto.imagen4);
                                                setIsModalOpen(true);
                                            }}
                                        />
                                    </SwiperSlide>
                                ) : (
                                    <>
                                    </>
                                )
                        }
                    </Swiper>
                </SwiperSlide>
                <div className="textDetail">
                    <h2 className="title">{producto.titulo}</h2>
                    <hr />
                    <div className="deFLexBuet">
                        <h4>{producto.anio}</h4> |
                        <h4>{producto.marca} {producto.modelo}</h4>
                    </div>
                    <Anchor to={`https://www.google.com/maps?q=${encodeURIComponent(producto.ubicacion)}`} target="_blank">{producto.ubicacion}</Anchor>

                    <h5 className="price">
                        {moneda} {String(producto?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}

                    </h5>

                    <p>{producto.descripcion}</p>
                    {
                        contactos.filter(filtered => filtered.idUsuario === producto.idUsuario).length > 0 ? (
                            contactos.filter(filtered => filtered.idUsuario === producto.idUsuario).map(itemC => (
                                <>
                                    <div className="deFLexProfile">
                                        <img src={logo} alt="Motos de segunda logo" />
                                        <div>
                                            <span>Vendedor {itemC.tipo}</span>
                                            <h4>{itemC.nombre}</h4>
                                        </div>
                                    </div>
                                    <div className='deFlexBtnContact'>
                                        <button
                                            className='contactBtn'
                                            onClick={() => openModal(contactos.find(contacto => contacto?.idUsuario === itemC?.idUsuario))}
                                        >
                                            Contactar
                                        </button>
                                        <button className="wpp" onClick={() => handleWhatsappMessage(itemC)}>
                                            <i className="fa fa-whatsapp"></i>
                                        </button>
                                    </div>
                                </>
                            ))
                        ) : (

                            <div className="deFLexProfile">
                                <img src={logo} alt="Motos de segunda logo" />
                                <h4>No hay datos del vendedor</h4>
                            </div>
                        )
                    }



                </div>
            </div>

            <Modal2 isOpen={modalIsOpen} onRequestClose={closeModal} className="modalData" overlayClassName="overlayModal">
                {contactoSeleccionado && (
                    <div className='DataModal'>
                        <h3>Contacto del Vendedor</h3>
                        <div className="deFLexProfile">
                            <img src={logo} alt="Motos de segunda logo" />
                            <div>
                                <h5>{contactoSeleccionado.tipo}</h5>
                                <h4>{contactoSeleccionado.nombre}</h4>
                            </div>
                        </div>
                        <span>Acompañamiento, confianza y seguridad en la compra de tu vehículo</span>
                        <div className='deFlexBtnsData'>
                            <button onClick={handleWhatsappClick} className='btnWpp'><i className="fa fa-whatsapp"></i>  {contactoSeleccionado?.telefono}</button>
                            <button onClick={handleCallClick} className='btnEmail'><i className="fa fa-phone"></i>  {contactoSeleccionado?.telefono}</button>
                        </div>
                        <button onClick={handleEmailClick} className='btnEmail'><i className="fa fa-envelope"></i>  {contactoSeleccionado?.email}</button>

                    </div>
                )}
            </Modal2>
            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                center
                classNames={{
                    modal: 'custom-modal',
                }}
            >
                <img src={modalImage} alt={producto.titulo} />
            </Modal>

            <div className="detailText">

                <strong>Tipo   <span>{producto.tipo}</span></strong>
                <strong>Cilindrada   <span>{producto.cilindrada}</span></strong>
                <strong>Kilómetros   <span>{producto.kilometros}</span></strong>
                <strong>Condición <span>{producto.condicion}</span></strong>


            </div>

            <h3 className="titleSection">Vehículos que podrían interesarte</h3>

            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                slidesPerView={'auto'}
                id='swiper_container_products'
                autoplay={{ delay: 3000 }}
            >
                {productos?.slice(0, 10)?.map(item => (
                    <SwiperSlide key={item.idProducto} id='SwiperSlide-scroll-products-masvendidos'>
                        <a class='cardProdcutmasVendido' href={`/moto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                            <img src={obtenerImagen(item)} alt={item?.titulo} />
                            <h6 className='masVendido'>Recomendado</h6>
                            <div className='cardText'>
                                <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                <strong>{item.anio} - {item.kilometros}</strong>
                                <h4>{item.titulo}</h4>
                            </div>
                        </a>
                    </SwiperSlide>
                ))}
            </Swiper>

        </div >

    )
}






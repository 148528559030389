import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Link as Anchor, useNavigate, useLocation } from 'react-router-dom';
import logo from '../../images/logo.png';
import baseURL from '../url';
import 'swiper/swiper-bundle.css';
import Profile from '../Profile/Profile';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMotorcycle } from '@fortawesome/free-solid-svg-icons';
import Favoritos from '../Favoritos/Favoritos';
import InputSerach from '../InputSerach/InputSearchs';
import AuthUsuario from '../AuthUsuario/AuthUsuario';

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header>
            <nav>
                <Anchor to={`/`} className='logo'>
                    <img src={logo} alt="Motos de segunda logo" />
                </Anchor>
                <div className='deFLexNavs'>
                    <InputSerach />
                    <Anchor to={`/blog`} className='FavoriteIcon'>
                        <FontAwesomeIcon icon={faBook} />  <span>Blog</span>
                    </Anchor>
                    <Anchor to={`/motos`} className='FavoriteIcon'>
                        <FontAwesomeIcon icon={faMotorcycle} />  <span>Motos</span>
                    </Anchor>
                    <Favoritos />
                    <AuthUsuario />
                    <div className={`nav_toggle ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={() => setIsOpen(false)}
                    className="modalNav"
                    overlayClassName="overlay"
                >
                    <div className="modalNav-content">
                        <Profile />
                        <AuthUsuario />
                    </div>
                </Modal>
            </nav>
        </header>
    );
}

import React, { useEffect, useState } from 'react';
import { Link as Anchor } from "react-router-dom";
import './BannersHome.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'react-toastify/dist/ReactToastify.css';
import banner1 from '../../images/banner1.png'
import banner2 from '../../images/banner2.png'
import banner3 from '../../images/banner3.png'
import banner4 from '../../images/banner4.png'

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function BannersHome() {



    const baners = [

        {
            img: banner1,
            titulo: '',
            texto: '',

        },
        {
            img: banner2,
            titulo: '',
            texto: '',

        },
        {
            img: banner3,
            titulo: '',
            texto: '',

        },
        {
            img: banner4,
            titulo: '',
            texto: '',

        },
    ]

    return (
        <div className='BannersHome' >

            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                slidesPerView={'auto'}
                id='swiper_container_home'
                autoplay={{ delay: 3000 }}
            >
                {baners.map(item => (
                    <SwiperSlide id='SwiperSlide-scroll-banner-home'>
                        <Anchor to={`/motos`}>
                            <img src={item.img} alt="Motos de segunda banner" />
                        </Anchor>
                    </SwiperSlide>
                ))}
            </Swiper>

        </div>
    );
}

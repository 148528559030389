import { React, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, } from 'react-router';
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import './LoginGoogle.css';
import baseURL from '../url';
import clientGoogle from '../url';
export default function LoginGoogle() {

    const navigate = useNavigate();

    const clientID =
        "901329466549-imbkgdg5udchc3i7a2so08b42avdnqb2.apps.googleusercontent.com";

    useEffect(() => {
        const start = () => {
            gapi.auth2.init({
                clientId: clientID,
            });
        };
        gapi.load("client:auth2", start);
    }, []);

    const onSuccess = async (googleResponse) => {
        let url = `${baseURL}/loginPostGoogle.php`; // Ajusta la URL según tu código para el inicio de sesión con Google

        try {
            const { name, email, googleId } = googleResponse.profileObj;

            // Datos necesarios para el inicio de sesión con Google en el servidor
            const formData = new FormData();
            formData.append('nombre', name);
            formData.append('email', email);
            formData.append('contrasena', googleId);
            formData.append('iniciar_sesion_google', true); // Ajusta el nombre del parámetro según tu código

            const serverResponse = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (serverResponse.ok) {
                const data = await serverResponse.json();
                if (data.mensaje) {
                    console.log(data.mensaje);
                    console.log(data.usuario);
                    toast.success(data.mensaje);
                    setTimeout(() => {
                        navigate('/dashboard');
                    }, 1000);
                } else if (data.error) {
                    console.log(data.error);
                    toast.error(data.error);
                }
            } else {
                throw new Error('Error en la solicitud al servidor');
            }
        } catch (error) {
            console.error(error);
            toast.error(error.message);

        }
    };



    const onFailure = () => {
        console.log("Something went wrong");
    };


    return (
        <div>
            <ToastContainer />

            <GoogleLogin
                className="google"
                buttonText="Continuar con Google"
                clientId={clientID}
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={"single_host_origin"}
            />

        </div>
    );
}

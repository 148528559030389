import React from 'react'
import DetailPublic from '../../Components/DetailPublic/DetailPublic'

export default function PublicacionDetail() {
    return (
        <div className='pageDetail'>
            <DetailPublic />

        </div>
    )
}

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './BannersModal.css';
import baseURL from '../url';
import moneda from '../moneda';
import { Link as Anchor } from 'react-router-dom';

export default function BannersModal() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [producto, setProducto] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const isModalClosed = sessionStorage.getItem('modalClosedProduct');
        if (!isModalClosed) {

            setTimeout(() => {
                cargarProductos();
            }, 2000);
        }
    }, []);

    const handleCloseModal = () => {
        sessionStorage.setItem('modalClosedProduct', 'true');
        setModalIsOpen(false);
    };

    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                const productosData = data?.productos;
                if (productosData && productosData.length > 0) {
                    const randomIndex = Math.floor(Math.random() * productosData.length);
                    setProducto(productosData[randomIndex]);
                    setModalIsOpen(true);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar productos:', error);
                setLoading(false);
            });
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={handleCloseModal}
            className="modal-dev"
            overlayClassName="overlay-dev"
            ariaHideApp={false}
        >
            <div className='modal-dev-contain'>
                {loading ? (
                    <div className='loadingBanner'>
                        {/* Loading spinner or message */}
                    </div>
                ) : (
                    producto && (
                        <div className='banner-dev-modal'>
                            <img src={producto?.imagen1 || producto?.imagen2 || producto?.imagen3 || producto?.imagen4} alt={`producto-${producto.idProducto}`} />
                            <div className='product-slide-text'>
                                <div className='deFlexSlide'>
                                    <img src={producto?.imagen1 || producto?.imagen2 || producto?.imagen3 || producto?.imagen4} alt={`producto-${producto.titulo}`} />
                                    <div className='deColumnlide'>
                                        <strong>{producto.anio} - {producto.kilometros}km</strong>
                                        <h3>{producto.titulo}</h3>
                                    </div>
                                </div>
                                <h5>{moneda} {String(producto.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                <Anchor to={`/moto/${producto.idProducto}/${producto.titulo?.replace(/\s+/g, '-')}`}>
                                    Ver Moto
                                </Anchor>
                            </div>
                        </div>
                    )
                )}
            </div>
        </Modal>
    );
}

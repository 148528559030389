import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faEye } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import baseURL from '../../url';
import NewPublicacion from '../NewPublicacion/NewPublicacion';
import { Link as Anchor } from "react-router-dom";
export default function PublicacionesData() {
    const [publicaciones, setPublicaciones] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevoTitulo, setNuevoTitulo] = useState('');
    const [nuevaDescripcion, setNuevaDescripcion] = useState('');
    const [nuevaCategoria, setNuevaCategoria] = useState('');
    const [publicacion, setPublicacion] = useState({});
    const [modalImagenVisible, setModalImagenVisible] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState('');
    const [filtroId, setFiltroId] = useState('');
    const [filtroTitulo, setFiltroTitulo] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    const [imagenPreview, setImagenPreview] = useState(null);
    const [nuevaImagen, setNuevaImagen] = useState(null);
    const [selectedSection, setSelectedSection] = useState('texto');

    const cerrarModalImagen = () => {
        setModalImagenVisible(false);
    };
    const abrirModalImagenSeleccionada = (imagen) => {
        setImagenSeleccionada(imagen);
        setModalImagenVisible(true);
    };


    useEffect(() => {
        cargarPublicaiones();

    }, []);

    useEffect(() => {
        // Actualiza el valor del select cuando cambia el estado nuevoEstado
        setNuevoTitulo(publicacion.titulo);
        setNuevaDescripcion(publicacion.descripcion);
        setNuevaCategoria(publicacion.categoria);

    }, [publicacion]);



    const cargarPublicaiones = () => {
        fetch(`${baseURL}/publicacionesGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {

                setPublicaciones(data.publicaciones || []);
            })
            .catch(error => console.error('Error al cargar publicaciones:', error));
    };


    const eliminar = (idPublicacion) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/publicacionesDelete.php?idPublicacion=${idPublicacion}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarPublicaiones();
                    })
                    .catch(error => {
                        console.error('Error al eliminar el Producto:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setPublicacion(item);
        setNuevoTitulo(item.titulo);
        setNuevaDescripcion(item.descripcion);
        setNuevaCategoria(item.categoria);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };


    const Filtrados = publicaciones.filter(item => {
        const idMatch = item.idPublicacion.toString().includes(filtroId);
        const tituloMatch = !filtroTitulo || item.titulo.includes(filtroTitulo);
        return idMatch && tituloMatch;
    });

    const descargarExcel = () => {
        const data = Filtrados.map(item => ({
            idPublicacion: item.idPublicacion,
            Titulo: item.titulo,
            Descripcion: item.descripcion,
            Categoria: item.categoria,
            Fecha: item.createdAt,
            Imagen1: item.imagen1,

        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Publicaciones');
        XLSX.writeFile(wb, 'Publicaciones.xlsx');
    };

    const descargarPDF = () => {
        const pdf = new jsPDF();
        pdf.text('Lista de Publicaciones', 10, 10);

        const columns = [
            { title: 'idPublicacion', dataKey: 'idPublicacion' },
            { title: 'Titulo', dataKey: 'titulo' },
            { title: 'Descripcion', dataKey: 'descripcion' },
            { title: 'Categoria', dataKey: 'categoria' },
            { title: 'Fecha', dataKey: 'createdAt' },
        ];

        const data = Filtrados.map(item => ({
            idPublicacion: item.idPublicacion,
            Titulo: item.titulo,
            Descripcion: item.descripcion,
            Categoria: item.categoria,
            Fecha: item.createdAt,

        }));

        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
        });

        pdf.save('Publicaciones.pdf');
    };

    const recargar = () => {
        cargarPublicaiones();
    };
    const invertirOrden = () => {
        setPublicaciones([...publicaciones].reverse());
        setOrdenInvertido(!ordenInvertido);
    };


    const handleUpdateText = (idPublicacion) => {
        const payload = {

            titulo: nuevoTitulo !== '' ? nuevoTitulo : publicacion.titulo,
            descripcion: nuevaDescripcion !== '' ? nuevaDescripcion : publicacion.descripcion,
            categoria: nuevaCategoria !== '' ? nuevaCategoria : publicacion.categoria,

        };

        fetch(`${baseURL}/publicacionesTextPut.php?idPublicacion=${idPublicacion}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {

                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {

                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    cargarPublicaiones();
                    cerrarModal()
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    };

    const handleFileChange = (event, setFile, setPreview) => {
        const file = event.target.files[0];

        if (file) {
            // Crear una URL de objeto para la imagen seleccionada
            const previewURL = URL.createObjectURL(file);
            setFile(file);
            setPreview(previewURL);
        }
    };
    const handleEditarImagenBanner = (idPublicacion) => {
        const formData = new FormData();
        formData.append('idPublicacion', idPublicacion);
        formData.append('updateAction', 'update'); // Campo adicional para indicar que es una actualización

        if (nuevaImagen) {
            formData.append('imagen1', nuevaImagen);
        }


        fetch(`${baseURL}/publicacionesImagePut.php`, {
            method: 'POST',  // Cambiado a POST
            body: formData
        })
            .then(response => {
                // Manejar el caso cuando la respuesta no es un JSON válido o está vacía
                if (!response.ok) {
                    throw new Error('La solicitud no fue exitosa');

                }

                return response.json();
            })
            .then(data => {
                if (data.error) {

                    toast.error(data.error);
                    console.log(formData)
                } else {

                    toast.success(data.mensaje);
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error)
                toast.error(error.message);
                console.log(formData)
                console.log(idPublicacion)
            });
    };

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };




    return (
        <div>

            <ToastContainer />
            <div className='deFlexContent'>

                <div className='deFlex2'>
                    <NewPublicacion />
                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                </div>
                <div className='filtrosContain'>
                    <div className='inputsColumn'>
                        <input type="number" value={filtroId} onChange={(e) => setFiltroId(e.target.value)} placeholder='Id' />
                    </div>

                    <div className='inputsColumn'>
                        <input type="text" value={filtroTitulo} onChange={(e) => setFiltroTitulo(e.target.value)} placeholder='Titulo' />
                    </div>


                    <button className='reload' onClick={recargar}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>

                </div>

            </div>


            {modalImagenVisible && (
                <div className="modalImg">
                    <div className="modal-contentImg">


                        <span className="close2" onClick={cerrarModalImagen}>
                            &times;
                        </span>

                        <img src={imagenSeleccionada} alt="Imagen Seleccionada" />
                    </div>
                </div>
            )}

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>

                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Texto
                                </button>
                                <button
                                    className={selectedSection === 'imagenes' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('imagenes')}
                                >
                                    Editar Imagenes
                                </button>
                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Titulo</legend>
                                    <input
                                        type="text"
                                        value={nuevoTitulo !== '' ? nuevoTitulo : publicacion.titulo}
                                        onChange={(e) => setNuevoTitulo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Categoria</legend>
                                    <input
                                        type="text"
                                        value={nuevaCategoria !== '' ? nuevaCategoria : publicacion.categoria}
                                        onChange={(e) => setNuevaCategoria(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset id='descripcion'>
                                    <legend>Descripcion</legend>
                                    <textarea
                                        type="text"
                                        id="nuevaDescripcion"
                                        name="nuevaDescripcion"
                                        required
                                        value={nuevaDescripcion !== '' ? nuevaDescripcion : publicacion.descripcion}
                                        onChange={(e) => setNuevaDescripcion(e.target.value)}
                                    />
                                </fieldset>

                            </div>

                            <button className='btnPost' onClick={() => handleUpdateText(publicacion.idPublicacion)} >Guardar </button>

                        </div>

                        <div className='sectionImg' style={{ display: selectedSection === 'imagenes' ? 'flex' : 'none' }}>
                            <div className='previevProduct'>

                                {imagenPreview ? (
                                    <img src={imagenPreview} alt="Vista previa de la imagen" onClick={() => abrirModalImagenSeleccionada(publicacion.imagen1)} />
                                ) : (
                                    <>
                                        {publicacion.imagen1 ? (
                                            <img src={publicacion.imagen1} alt="imagen" onClick={() => abrirModalImagenSeleccionada(publicacion.imagen1)} />

                                        ) : (
                                            <span className='imgNone'>
                                                No hay imagen

                                            </span>
                                        )}
                                    </>
                                )}

                            </div>
                            <fieldset>
                                <legend>Editar Imagen1 </legend>
                                <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setNuevaImagen, setImagenPreview)} />
                            </fieldset>

                            <button className='btnPost' onClick={() => handleEditarImagenBanner(publicacion.idPublicacion)}>Guardar </button>

                        </div>



                    </div>
                </div>
            )}
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Id publicacion</th>
                            <th>Titulo</th>
                            <th>Categoria</th>
                            <th>Dscripcion</th>
                            <th>Imagen</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>

                        {Filtrados.map(item => (
                            <tr key={item.idPublicacion}>
                                <td>{item.idPublicacion}</td>
                                <td>{item.titulo}</td>

                                <td style={{ color: '#008000' }}>
                                    {item?.categoria}
                                </td>
                                <td>{item.descripcion}</td>
                                <td>
                                    {item.imagen1 ? (
                                        <img src={item.imagen1} alt="imagen1" />
                                    ) : (
                                        <span className='imgNonetd'>
                                            Sin imagen
                                        </span>
                                    )}
                                </td>


                                <td>
                                    <button className='eliminar' onClick={() => eliminar(item.idPublicacion)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <Anchor className='editar' to={`/blog/${item?.idPublicacion}/${item?.titulo?.replace(/\s+/g, '-')}`}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Anchor>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import './NewProduct.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';
import marcas from '../../marcas';
import ubicaciones from '../../ubicaciones';
import tipos from '../../tipos';
import condiciones from '../../condiciones';
import combustibles from '../../combustibles';
export default function NewProduct() {
    const [mensaje, setMensaje] = useState('');
    const [imagenPreview1, setImagenPreview1] = useState(null);
    const [imagenPreview2, setImagenPreview2] = useState(null);
    const [imagenPreview3, setImagenPreview3] = useState(null);
    const [imagenPreview4, setImagenPreview4] = useState(null);
    const [descripcion, setDescripcion] = useState('');
    const [titulo, setTitulo] = useState('');
    const [precio, setPrecio] = useState('');
    const [isImage1Selected, setIsImage1Selected] = useState(false);
    const [isImage2Selected, setIsImage2Selected] = useState(false);
    const [isImage3Selected, setIsImage3Selected] = useState(false);
    const [isImage4Selected, setIsImage4Selected] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [ubicacion, setUbicacion] = useState('');
    const [modelo, setModelo] = useState('');
    const [cilindrada, setCilindrada] = useState('');
    const [tipo, setTipo] = useState('');
    const [anio, setAnio] = useState('');
    const [condicion, setCondicion] = useState('');
    const [marca, setMarca] = useState('');
    const [kilometros, setKilometros] = useState('');
    const [combustible, setCombustible] = useState('');
    const [usuario, setUsuario] = useState({});

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleImagenChange = (event, setImagenPreview, setIsImageSelected) => {
        const file = event.target.files[0];

        if (file) {
            const previewURL = URL.createObjectURL(file);
            setImagenPreview(previewURL);
            setIsImageSelected(true);
        }
    };

    const crear = async () => {
        const form = document.getElementById("crearForm");
        const formData = new FormData(form);

        // Agregar idUsuario al formData
        formData.append('idUsuario', usuario.idUsuario);

        const resetForm = () => {
            form.reset();
            setImagenPreview1(null);
            setImagenPreview2(null);
            setImagenPreview3(null);
            setImagenPreview4(null);
            setIsImage1Selected(false);
            setIsImage2Selected(false);
            setIsImage3Selected(false);
            setIsImage4Selected(false);
        };

        setMensaje('');

        // Validar que todos los campos estén completos
        if (
            !formData.get('titulo') ||
            !formData.get('precio') ||
            !formData.get('imagen1') ||
            !formData.get('imagen2') ||
            !formData.get('imagen3') ||
            !formData.get('imagen4')
        ) {
            toast.error('Por favor, complete todos los campos correctamente.');
            return;
        }

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/productosPost.php`, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                resetForm();
                toast.success(data.mensaje);
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
                console.log(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    const handleCondicion = (e) => {
        setCondicion(e.target.value);
    };
    const handleAño = (e) => {
        setAnio(e.target.value);
    };
    const handleModelo = (e) => {
        setModelo(e.target.value);
    };
    const handleUbicacion = (e) => {
        setUbicacion(e.target.value);
    };

    const handleTipo = (e) => {
        setTipo(e.target.value);
    };
    const handleCombustible = (e) => {
        setCombustible(e.target.value);
    };
    const handleCilindrada = (e) => {
        setCilindrada(e.target.value);
    };
    const handleMarca = (e) => {
        setMarca(e.target.value);
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };

        fetchData();
    }, []);



    return (
        <div className='NewContain'>
            <ToastContainer />
            <button onClick={toggleModal} className='btnSave'>
                <span>  +</span> Agregar
            </button>
            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>Agregar Producto</button>
                            <span className="close" onClick={toggleModal}>&times;</span>
                        </div>
                        <form id="crearForm">
                            <div className='flexGrap'>
                                <fieldset id='deNoneInput'>
                                    <legend>IdUsuario</legend>
                                    <input
                                        type="number"
                                        id="idUsuario"
                                        name="idUsuario"
                                        required
                                        value={usuario.idUsuario}
                                        readOnly
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Título (obligatorio)</legend>
                                    <input
                                        type="text"
                                        id="titulo"
                                        name="titulo"
                                        required
                                        value={titulo}
                                        onChange={(e) => setTitulo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Precio (obligatorio)</legend>
                                    <input
                                        type="number"
                                        id="precio"
                                        name="precio"
                                        min="0"
                                        step="0.01"
                                        required
                                        value={precio}
                                        onChange={(e) => setPrecio(e.target.value)}
                                    />
                                </fieldset>


                                <fieldset>
                                    <legend>Ubicación (obligatorio)</legend>
                                    <select
                                        id="ubicacion"
                                        name="ubicacion"
                                        value={ubicacion}
                                        onChange={handleUbicacion}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            ubicaciones?.map(item => (
                                                <option value={item?.value}>{item?.ubicacion}</option>
                                            ))
                                        }

                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>Marca (obligatorio)</legend>
                                    <select
                                        id="marca"
                                        name="marca"
                                        value={marca}
                                        onChange={handleMarca}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            marcas?.map(item => (
                                                <option value={item?.value}>{item?.marca}</option>
                                            ))
                                        }
                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>Modelo (obligatorio)</legend>
                                    <input
                                        type="text"
                                        id="modelo"
                                        name="modelo"
                                        value={modelo}
                                        onChange={handleModelo}
                                    />
                                </fieldset>





                                <fieldset>
                                    <legend>Año (obligatorio)</legend>
                                    <select
                                        id="anio"
                                        name="anio"
                                        value={anio}
                                        onChange={handleAño}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {Array.from({ length: 35 }, (_, i) => 2025 - i).map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                    </select>
                                </fieldset>


                                <fieldset>
                                    <legend>Condición (obligatorio)</legend>
                                    <select
                                        id="condicion"
                                        name="condicion"
                                        value={condicion}
                                        onChange={handleCondicion}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            condiciones?.map(item => (
                                                <option value={item?.value}>{item?.condicion}</option>
                                            ))
                                        }


                                    </select>
                                </fieldset>

                                <fieldset>
                                    <legend>Tipo (obligatorio)</legend>
                                    <select
                                        id="tipo"
                                        name="tipo"
                                        value={tipo}
                                        onChange={handleTipo}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            tipos?.map(item => (
                                                <option value={item?.value}>{item?.tipo}</option>
                                            ))
                                        }

                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Cilindrada (obligatorio)</legend>
                                    <input
                                        type="number"
                                        id="cilindrada"
                                        name="cilindrada"
                                        value={cilindrada}
                                        onChange={handleCilindrada}
                                        min="0"
                                        step="0.01"
                                        required

                                    />
                                </fieldset>



                                <fieldset>
                                    <legend>Kilómetros (obligatorio)</legend>
                                    <input
                                        type="number"
                                        id="kilometros"
                                        name="kilometros"
                                        min="0"
                                        step="0.01"
                                        required
                                        value={kilometros}
                                        onChange={(e) => setKilometros(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Combustible (obligatorio)</legend>
                                    <select
                                        id="combustible"
                                        name="combustible"
                                        value={combustible}
                                        onChange={handleCombustible}
                                    >
                                        <option value="">Selecciona opción</option>
                                        {
                                            combustibles?.map(item => (
                                                <option value={item?.value}>{item?.combustible}</option>
                                            ))
                                        }

                                    </select>
                                </fieldset>

                                <fieldset id='descripcion'>
                                    <legend>Descripción</legend>
                                    <textarea
                                        id="descripcion"
                                        name="descripcion"
                                        required
                                        value={descripcion}
                                        onChange={(e) => setDescripcion(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Imagen 1</legend>
                                    <input
                                        type="file"
                                        id="imagen1"
                                        name="imagen1"
                                        accept="image/*"
                                        onChange={(e) => handleImagenChange(e, setImagenPreview1, setIsImage1Selected)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Imagen 2</legend>
                                    <input
                                        type="file"
                                        id="imagen2"
                                        name="imagen2"
                                        accept="image/*"
                                        onChange={(e) => handleImagenChange(e, setImagenPreview2, setIsImage2Selected)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Imagen 3</legend>
                                    <input
                                        type="file"
                                        id="imagen3"
                                        name="imagen3"
                                        accept="image/*"
                                        onChange={(e) => handleImagenChange(e, setImagenPreview3, setIsImage3Selected)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Imagen 4</legend>
                                    <input
                                        type="file"
                                        id="imagen4"
                                        name="imagen4"
                                        accept="image/*"
                                        onChange={(e) => handleImagenChange(e, setImagenPreview4, setIsImage4Selected)}
                                    />
                                </fieldset>
                            </div>
                            {(isImage1Selected || isImage2Selected || isImage3Selected || isImage4Selected) &&
                                <div className='previevCategori'>
                                    {isImage1Selected && <img src={imagenPreview1} alt="Vista previa 1" />}
                                    {isImage2Selected && <img src={imagenPreview2} alt="Vista previa 2" />}
                                    {isImage3Selected && <img src={imagenPreview3} alt="Vista previa 3" />}
                                    {isImage4Selected && <img src={imagenPreview4} alt="Vista previa 4" />}
                                </div>
                            }
                            {mensaje ? (
                                <button type="button" className='btnLoading' disabled>
                                    {mensaje}
                                </button>
                            ) : (
                                <button type="button" onClick={crear} className='btnPost'>
                                    Agregar
                                </button>
                            )}

                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import Spiner from '../Spiner/Spiner';
import baseURL from '../../url';
import Swal from 'sweetalert2'; // Importar SweetAlert2
import { useNavigate } from 'react-router-dom'; // Para redirección

export default function AlertContact() {
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);
    const [contactos, setContactos] = useState([]);
    const navigate = useNavigate(); // Hook para navegación

    useEffect(() => {
        cargarContacto();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    useEffect(() => {
        // Verificar si el rol no es 'admin' y si el usuario logueado no tiene un contacto
        if (!loading && usuario?.idUsuario && usuario?.rol !== 'admin' && !contactos?.some(contacto => contacto.idUsuario === usuario.idUsuario)) {
            Swal.fire({
                title: 'Importante',
                text: 'Debes registrar tu información de contacto',
                icon: 'warning',
                confirmButtonText: 'OK',
                allowOutsideClick: false, // No permitir cerrar haciendo clic afuera
                allowEscapeKey: false // No permitir cerrar con la tecla Escape
            }).then(() => {
                navigate('/dashboard/informacion-de-contacto'); // Redirigir a la página de contacto
            });
        }
    }, [loading, usuario, contactos, navigate]);

    return (
        <div>
            {loading ? (
                <Spiner />
            ) : (
                <>{/* Si ya tiene contactos o es admin, no mostramos nada */}</>
            )}
        </div>
    );
}

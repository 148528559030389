const contact = [


    {
        nombre: 'Motos de segunda',
        telefono: 573133555007,
        email: 'motosenventa.co@gmail.com',
        direccion: 'Colombia',
        instagram: 'https://www.instagram.com/motos.de.segunda/',
        facebook: 'https://www.facebook.com/motosdesegundacom'
    }

]
export default contact
import React, { useState, useEffect } from 'react';
import Logo from '../../images/logo.png';
import { Link as Anchor } from 'react-router-dom';
import './AnuncioLogin.css';
import baseURL from '../url';
import LoginGoogle from '../LoginGoogle/LoginGoogle';
import { useNavigate, } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
export default function AnuncioLogin() {
    const [showUserDialog, setShowUserDialog] = useState(false);
    const [usuario, setUsuario] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [mensaje2, setMensaje2] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/userLogued.php`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setUsuario(data);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener datos:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const isClosed = sessionStorage.getItem('modalClosed');
        if (!isClosed) {
            setTimeout(() => {
                setShowUserDialog(true);
            }, 5000);
        }
    }, []);

    const closeModal = () => {
        setShowUserDialog(false);
        sessionStorage.setItem('modalClosed', 'true');
    };



    return (
        <div>
            {loading ? (
                <></>
            ) : usuario.idUsuario ? (
                <>
                    {/* Renderizar contenido para usuarios logueados */}
                </>
            ) : (
                <>
                    {showUserDialog && (
                        <div className='AnuncioModalContain'>
                            <div className='deFlexbtn'>
                                <div className='deFlexText'>
                                    <img src={Logo} alt="efecto vial web" />
                                    <h4>Debes iniciar sesión para publicar</h4>
                                </div>
                                <button className='closeModalMain' onClick={closeModal}>X</button>
                            </div>
                            <Anchor onClick={closeModal} className='btnAnchor'>
                                Continuar sin cuenta
                            </Anchor>
                            <LoginGoogle />
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

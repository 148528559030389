import React, { useState, useEffect } from "react";
import "./InputSearchs.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import baseURL from '../url';

export default function InputSearchs() {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredResults, setFilteredResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [visibleCount, setVisibleCount] = useState(10);
    const handleShowMore = () => {
        setVisibleCount(prevCount => prevCount + 10);
    };
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        cargarProductos();
    }, []);

    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos || []);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);

        const filteredResults = productos.filter((producto) => {
            return (
                producto.titulo.toLowerCase().includes(searchTerm) ||
                producto.modelo.toLowerCase().includes(searchTerm) ||
                producto.anio.toString().includes(searchTerm)
            );
        });

        setFilteredResults(filteredResults);
        setShowResults(searchTerm !== "");
        setNoResults(searchTerm !== "" && filteredResults.length === 0);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="fondo-input">
            <div className="search-container">
                <div className="inputSerchPc" onClick={openModal}>
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                    <span>Buscar...</span>
                </div>
                <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="modalInput" overlayClassName="overlayInput">
                    <fieldset className="inputSearch">
                        <FontAwesomeIcon icon={faSearch} className="search-icon" />
                        <input
                            type="text"
                            placeholder="Buscar..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="input"
                        />
                    </fieldset>
                    {showResults && (
                        <div className="modalSearch">
                            {filteredResults?.slice(0, visibleCount)?.map((producto) => (
                                <div key={producto.idProducto}>
                                    <Link to={`/moto/${producto.idProducto}/${producto.titulo.replace(/\s+/g, '-')}`} onClick={closeModal}>
                                        <img src={producto.imagen1} alt="" />
                                        <p>{producto.titulo}</p>
                                    </Link>
                                </div>
                            ))}
                            {noResults && <p>No se encontraron resultados.</p>}
                            {filteredResults?.length > visibleCount && (
                                <button onClick={handleShowMore} id="show-more-btn">
                                    Mostrar  más </button>
                            )}
                        </div>
                    )}
                </Modal>
            </div>
        </div>
    );
}

const marcas = [
    { marca: 'Akt', value: 'Akt' },
    { marca: 'Apollo Motors', value: 'Apollo Motors' },
    { marca: 'Aprilia', value: 'Aprilia' },
    { marca: 'Auteco', value: 'Auteco' },
    { marca: 'Ayco', value: 'Ayco' },
    { marca: 'Bajaj', value: 'Bajaj' },
    { marca: 'Benelli', value: 'Benelli' },
    { marca: 'Bengala', value: 'Bengala' },
    { marca: 'Bera', value: 'Bera' },
    { marca: 'Bimota', value: 'Bimota' },
    { marca: 'BMW', value: 'BMW' },
    { marca: 'CFMoto', value: 'CFMoto' },
    { marca: 'Dinamo', value: 'Dinamo' },
    { marca: 'Ducati', value: 'Ducati' },
    { marca: 'Harley', value: 'Harley' },
    { marca: 'Harley-Davidson', value: 'Harley-Davidson' },
    { marca: 'Hero', value: 'Hero' },
    { marca: 'Honda', value: 'Honda' },
    { marca: 'Husqvarna', value: 'Husqvarna' },
    { marca: 'Italika', value: 'Italika' },
    { marca: 'Jialing Motos', value: 'Jialing Motos' },
    { marca: 'Kawasaki', value: 'Kawasaki' },
    { marca: 'Keeway', value: 'Keeway' },
    { marca: 'KTM', value: 'KTM' },
    { marca: 'Kymko', value: 'Kymko' },
    { marca: 'Lifan', value: 'Lifan' },
    { marca: 'Moto Guzzi', value: 'Moto Guzzi' },
    { marca: 'MV Agusta', value: 'MV Agusta' },
    { marca: 'Norton', value: 'Norton' },
    { marca: 'Piaggio', value: 'Piaggio' },
    { marca: 'Pulsar', value: 'Pulsar' },
    { marca: 'Royal Enfield', value: 'Royal Enfield' },
    { marca: 'Sherco', value: 'Sherco' },
    { marca: 'Starker', value: 'Starker' },
    { marca: 'Suzuki', value: 'Suzuki' },
    { marca: 'Sym', value: 'Sym' },
    { marca: 'TVS', value: 'TVS' },
    { marca: 'Triumph', value: 'Triumph' },
    { marca: 'UM', value: 'UM' },
    { marca: 'Vento', value: 'Vento' },
    { marca: 'Vespa', value: 'Vespa' },
    { marca: 'Vitory', value: 'Vitory' },
    { marca: 'Yamaha', value: 'Yamaha' },
    { marca: 'YCF', value: 'YCF' },
    { marca: 'Zontes', value: 'Zontes' }
];

export default marcas;

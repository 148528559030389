import React from 'react'
import './ProductosLoadingPage.css'
export default function ProductosLoadingPage() {
    return (
        <div className='ProductosLoadingPage'>

            <div className='cardProductosLoadingPage'>

            </div>
            <div className='cardProductosLoadingPage'>

            </div>
            <div className='cardProductosLoadingPage'>

            </div>
            <div className='cardProductosLoadingPage'>

            </div>
            <div className='cardProductosLoadingPage'>

            </div>
            <div className='cardProductosLoadingPage'>

            </div>
            <div className='cardProductosLoadingPage'>

            </div>
            <div className='cardProductosLoadingPage'>

            </div>
            <div className='cardProductosLoadingPage'>

            </div>
        </div>
    )
}

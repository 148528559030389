const tipos = [
    { tipo: 'Calle', value: 'Calle' },
    { tipo: 'Chopper', value: 'Chopper' },
    { tipo: 'Cross', value: 'Cross' },
    { tipo: 'Crucero', value: 'Crucero' },
    { tipo: 'Cuatrimotos', value: 'Cuatrimotos' },
    { tipo: 'Custom', value: 'Custom' },
    { tipo: 'Deportivas', value: 'Deportivas' },
    { tipo: 'Doble propósito', value: 'Doble propósito' },
    { tipo: 'Eléctrico', value: 'Eléctrico' },
    { tipo: 'Enduro', value: 'Enduro' },
    { tipo: 'Mini Motos', value: 'Mini Motos' },
    { tipo: 'Motocarros', value: 'Motocarros' },
    { tipo: 'Naked', value: 'Naked' },
    { tipo: 'Scooters', value: 'Scooters' },
    { tipo: 'Touring', value: 'Touring' },
    { tipo: 'Trial', value: 'Trial' },
    { tipo: 'Triciclos', value: 'Triciclos' },
];

export default tipos;

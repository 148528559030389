const ubicacion = [
    { ubicacion: 'Bogotá', value: 'Bogotá' },
    { ubicacion: 'Medellín', value: 'Medellín' },
    { ubicacion: 'Cali', value: 'Cali' },
    { ubicacion: 'Barranquilla', value: 'Barranquilla' },
    { ubicacion: 'Cartagena', value: 'Cartagena' },
    { ubicacion: 'Bucaramanga', value: 'Bucaramanga' },
    { ubicacion: 'Manizales', value: 'Manizales' },
    { ubicacion: 'Pereira', value: 'Pereira' },
    { ubicacion: 'Santa Marta', value: 'Santa Marta' },
    { ubicacion: 'Cúcuta', value: 'Cúcuta' },
    { ubicacion: 'Ibagué', value: 'Ibagué' },
    { ubicacion: 'Villavicencio', value: 'Villavicencio' },
    { ubicacion: 'Armenia', value: 'Armenia' },
    { ubicacion: 'Neiva', value: 'Neiva' },
    { ubicacion: 'Sincelejo', value: 'Sincelejo' },
    { ubicacion: 'Tunja', value: 'Tunja' },
    { ubicacion: 'Popayán', value: 'Popayán' },
    { ubicacion: 'Montería', value: 'Montería' },
    { ubicacion: 'Pasto', value: 'Pasto' },
    { ubicacion: 'Valledupar', value: 'Valledupar' },
    { ubicacion: 'Riohacha', value: 'Riohacha' },
    { ubicacion: 'Quibdó', value: 'Quibdó' },
    { ubicacion: 'Florencia', value: 'Florencia' },
    { ubicacion: 'Mocoa', value: 'Mocoa' },
    { ubicacion: 'San Andrés', value: 'San Andrés' },
    { ubicacion: 'Yopal', value: 'Yopal' },
    { ubicacion: 'Arauca', value: 'Arauca' },
    { ubicacion: 'Leticia', value: 'Leticia' },
    { ubicacion: 'San José del Guaviare', value: 'San José del Guaviare' },
    { ubicacion: 'Mitú', value: 'Mitú' },
    { ubicacion: 'Inírida', value: 'Inírida' },
    { ubicacion: 'Puerto Carreño', value: 'Puerto Carreño' },
    { ubicacion: 'Sabaneta', value: 'Sabaneta' },
    { ubicacion: 'Girón', value: 'Girón' },
    { ubicacion: 'Funza', value: 'Funza' },
    { ubicacion: 'Soacha', value: 'Soacha' },
    { ubicacion: 'Florida', value: 'Florida' },
    { ubicacion: 'Rivera', value: 'Rivera' },
    { ubicacion: 'Acacías', value: 'Acacías' },
    { ubicacion: 'Envigado', value: 'Envigado' },
    { ubicacion: 'Antioquia', value: 'Antioquia' },
    { ubicacion: 'Valle del Cauca', value: 'Valle del Cauca' },
    { ubicacion: 'Cundinamarca', value: 'Cundinamarca' },
    { ubicacion: 'Santander', value: 'Santander' },
    { ubicacion: 'Risaralda', value: 'Risaralda' },
    { ubicacion: 'Caldas', value: 'Caldas' },
    { ubicacion: 'Atlántico', value: 'Atlántico' },
    { ubicacion: 'Tolima', value: 'Tolima' },
    { ubicacion: 'Bolívar', value: 'Bolívar' },
    { ubicacion: 'Boyacá', value: 'Boyacá' },
    { ubicacion: 'Córdoba', value: 'Córdoba' },
    { ubicacion: 'Caquetá', value: 'Caquetá' },
    { ubicacion: 'Cesar', value: 'Cesar' },
    { ubicacion: 'Casanare', value: 'Casanare' },
    { ubicacion: 'Chocó', value: 'Chocó' },
    { ubicacion: 'Guajira', value: 'Guajira' },
    { ubicacion: 'Huila', value: 'Huila' },
    { ubicacion: 'Magdalena', value: 'Magdalena' },
    { ubicacion: 'Meta', value: 'Meta' },
    { ubicacion: 'Nariño', value: 'Nariño' },
    { ubicacion: 'Norte de Santander', value: 'Norte de Santander' },
    { ubicacion: 'Putumayo', value: 'Putumayo' },
    { ubicacion: 'Quindío', value: 'Quindío' },
    { ubicacion: 'San Andrés y Providencia', value: 'San Andrés y Providencia' },
    { ubicacion: 'Sucre', value: 'Sucre' },
];

export default ubicacion;
